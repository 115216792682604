import React from "react";
import { graphql } from "gatsby";
import { Article } from "../models/article.model";
import PageWrapper from "../components/PageWrapper";
import { ROUTE_LINKS } from "../utils/routes";

const NewsArticle = (props: any) => {
  const article: Article = props.data.article.frontmatter;

  return (
    <PageWrapper path={ROUTE_LINKS.news}>
      <main className="flex flex-col max-w-6xl md:mx-auto px-2.5 py-12">
        <h2 className="font-bold text-xl mb-10 text-center">
          Matrix Rental Solutions Selected for NAR Tech Scale-Up Program, REACH
          Commercial
        </h2>
        <p className="mb-5">
          <strong>NEW YORK (April 7, 2022)</strong>&nbsp;–&nbsp;
          <a
            className="underline text-blue-500"
            href="https://mymatrixrent.com/"
          >
            Matrix Rental Solutions
          </a>
          , an AI-enabled, tenant analysis platform changing the landscape of
          the rental housing market, is one of nine companies selected for the
          2022 REACH Commercial program.&nbsp;
          <a className="underline text-blue-500" href="https://www.scv.vc/">
            Second Century Ventures
          </a>
          , the most active global fund in real estate technology, operates the
          program, which is backed by the &nbsp;
          <a
            href="https://www.nar.realtor/"
            className="underline text-blue-500"
          >
            National Association of Realtors
          </a>
          ® (NAR).
        </p>
        <p className="mb-5">
          The mission of REACH is to select and help accelerate the most
          promising new technology companies in real estate and adjacent
          industries, which is more important now than ever in a challenging
          housing market that requires innovative solutions to keep Realtors® at
          the forefront of the industry.
        </p>
        <p className="mb-5">
          Over nine months, Matrix Rental Solutions will receive mentorship,
          education, and support from a global network of more than 160 REACH
          portfolio companies. Additionally, the Matrix Rental Solutions team
          will be invited to participate in various industry conferences,
          tradeshows, and networking events.
        </p>
        <p className="mb-5">
          “We are excited and honored to be a participant in the 2022 REACH
          program alongside so many high-potential technology companies,” said{" "}
          <a
            href="https://www.linkedin.com/in/siphosimela/"
            className="underline text-blue-500"
          >
            Sipho Simela
          </a>
          , founder and CEO of Matrix Rental Solutions. “We’re looking forward
          to learning alongside some of the best and brightest minds in the real
          estate industry as we work to disrupt the tenant screening and
          approval process to make renting simple – and available – for all.”
        </p>
        <p className="mb-5">
          Headquartered in New York, Matrix Rental Solutions developed the first
          universal digital rental application built to help both renters and
          landlords. Using Matrix Asset & Income Analysis (MAIA), its
          proprietary scoring model, Matrix Rental Solutions produces a more
          complete upfront application that leads to faster processing time.
          MAIA connects to 16,000 banks and 90% of payroll providers, which
          leads to a faster, easier, and more cost-effective tenant screening
          process. Landlords and property managers can underwrite and approve
          tenants in less than 15 minutes—a manual process that currently takes
          days.
        </p>
        <p className="mb-5">
          Prospective tenants must only complete one application to get into
          their rental homes sooner. If their application is not selected, they
          can resubmit their application with one click – and without additional
          fees – to other properties leveraging Matrix Rental Solutions’
          technology at no additional charge.
        </p>
        <p className="mb-5">
          “The value of a platform like Matrix Rental Solutions is enormous –
          it’s changing how quick, thorough, and secure the tenant screening
          process can be in residential real estate,” said Tyler Thompson,
          Managing Partner of Second Century Ventures. “Consequently, landlords
          and Realtors® can help clients lease faster, and prospective tenants
          have a more equitable opportunity for housing. Matrix Rental Solutions
          is truly changing the game, and it benefits the entire industry.”
        </p>
        <p className="mb-5">
          The eight other companies selected into the 2022 REACH Commercial
          program include: Arx City, B-Line, CREtelligent, LeaseUp, SPACKLE,
          Stake, STRATAFOLIO and WhoseYourLandlord.
        </p>
        <h5 className="mb-5 font-bold text-base">
          About Matrix Rental Solutions
        </h5>
        <p className="mb-5">
          Matrix Rental Solutions is a tenant analysis fintech platform that is
          changing the landscape of the growing, $500 billion rental housing
          market. Matrix Asset & Income Analysis (MAIA), the company’s
          proprietary scoring model, produces a more thorough, inclusive, and
          faster tenant score that allows landlords and property managers to
          underwrite and approve tenants in less than 15 minutes—from what
          currently takes days. Learn more at&nbsp;
          <a
            className="underline text-blue-500"
            href="https://www.mymatrixrent.com/"
          >
            https://www.mymatrixrent.com/
          </a>
          .
        </p>
        <h5 className="mb-5 font-bold text-base">Contact:</h5>
        <p>
          Stephanie Roberts
          <br />
          Matrix Rental Solutions
          <br />
          <a
            className="underline text-blue-500"
            href="mailto:sroberts@mymatrixrent.com"
          >
            sroberts@mymatrixrent.com
          </a>
        </p>
      </main>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query($slug: Int) {
    article: markdownRemark(
      frontmatter: { slug: { eq: $slug } }
      fields: { category: { eq: "articles" } }
    ) {
      frontmatter {
        title
        date
        url
      }
    }
  }
`;

export default React.memo(NewsArticle);
